<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Primary Logo *
                </label>
                <single-upload-file 
                    :addition_class="'primary'" 
                    @responseURL="setLogoPrimary"
                    :current_file="form.logo_primary">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Secondary Logo
                </label>
                <single-upload-file 
                    :addition_class="'secondary'" 
                    @responseURL="setLogoSecondary"
                    :current_file="form.logo_secondary">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Bank *
                </label>
                <InputText type="text" placeholder="Nama Bank" v-model="form.bank_name" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kode Bank *
                </label>
                <InputText type="text" placeholder="Kode Bank" v-model="form.bank_code" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kode BI Fast *
                </label>
                <InputText type="text" placeholder="Kode BI Fast" v-model="form.bifast_code" :disabled="form.is_submit" />
            </div>
        </form>
    </div>
</template>

<script>
import SingleUploadFile from '@/components/SingleUploadFile.vue'

export default {
    props: ['data'],
    components: {
        SingleUploadFile,
    },
    data() {
        return {
            form: {
                id: null,
                logo_primary: '',
                logo_secondary: '',
                bank_code: '',
                bifast_code: '',
                bank_name: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.service_name = this.form.selected_service ? this.form.selected_service.name : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
        }
    },
    methods: {
        setLogoPrimary(url) {
            this.form.logo_primary = url
        },
        setLogoSecondary(url) {
            this.form.logo_secondary = url
        },
    },
}
</script>

<style lang="scss" scoped></style>
